<template>
  <div v-if="field && field.$error">
    <div v-for="(rule, key) in rules" :key="key">
      <small v-if="field[rule].$invalid" class="p-invalid">
        {{ field[rule].$message ? field[rule].$message : message }}
      </small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true
    },
    rules: {
      type: Array,
      required: true
    },
    message: {
      type: String,
      default: ''
    }
  }
}
</script>
