<template>
  <BaseBreadcrumb :items="breadcrumbs" />
  <h1>Create Project</h1>
  <div class="p-grid">
    <div class="p-col-12 p-sm-6">
      <ProjectForm @submitted="create" />
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import ProjectForm from '@/components/ProjectForm'
import { getNewKey } from '@/helpers/firebase'
import { db } from '@/services/firebase'
import { getUTC } from '@/helpers/date'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default {
  components: { ProjectForm, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const breadcrumbs = [
      { label: 'Projects', to: '/projects' },
      { label: 'Create' }
    ]

    function create(formData) {
      const newProjectKey = getNewKey('projects')
      const utc = getUTC(new Date())
      formData.created = utc
      formData.modified = utc

      db.ref('projects/' + newProjectKey)
        .set(formData)
        .then(() => {
          router.push({ name: 'ProjectIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Error creating project',
            detail: err.message,
            life: 3000
          })
        })
    }

    return { create, breadcrumbs }
  }
}
</script>
