export function formatDate({
  date,
  time,
  string,
  input
}: {
  date: Date
  time: boolean
  string: boolean
  input: boolean
}) {
  if (date instanceof Date) {
    const locale = 'en-CA'
    const month = date.getMonth() + 1
    const day = date.getDate()
    const year = date.getFullYear()
    let prependMonth = ''
    let prependDay = ''

    if (month < 10) {
      prependMonth = '0'
    }

    if (day < 10) {
      prependDay = '0'
    }

    if (time) {
      const hours = ('0' + date.getHours()).slice(-2)
      const minutes = ('0' + date.getMinutes()).slice(-2)
      const seconds = ('0' + date.getSeconds()).slice(-2)
      return `${year}-${prependMonth}${month}-${prependDay}${day}T${hours}:${minutes}:${seconds}`
    }

    if (string) {
      return date.toLocaleDateString(locale, {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
    }
    if (input) {
      return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
      })
    }

    return date.toLocaleDateString(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  }
  return date
}

export function simpleDate(date: Date) {
  if (date instanceof Date) {
    const locale = 'en-CA'

    return date.toLocaleDateString(locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    })
  }
  return date
}

export function getUTC(d: Date) {
  return d.toISOString()
}
